<template>
  <div class="container-fluid mt-5">
    <div class="row my-3 justify-content-center">
      <div class="col-12 col-lg-10">
        <div class="h3 d-none d-lg-block text-muted">
          Ich möchte mich anmelden
        </div>
        <div class="h3 d-block d-lg-none text-muted">Was bietet ihr an?</div>
        <div class="h1 d-none d-md-block company-color1">
          Anmeldeformular
          <br />
        </div>
        <div class="h1 d-block d-md-none company-color1">
          Anmeldeformular
          <br />
        </div>
        Um die Anmeldung zu beschleunigen kannst du dich im Formular unten für deine Ausbildung bei uns anmelden.
        <br />
        <small class="text-muted">
          Hast du Fragen?
          <br />Ruf uns an, schreib eine E-Mail oder komm vorbei! Wir nehmen uns
          Zeit für deine individuelle Beratung.
        </small>
      </div>
    </div>
    <div class="row my-5 justify-content-center">
      <div class="col-12 col-lg-10 px-3 text-center">
        
        <fa-element
          client_id="fahrschule_werner"
          type="form"
          form_name="anmeldeformular"
          hideTitle
          hideDescription
        />
      </div>
    </div>
    <div class="row my-5 justify-content-center">
      <div class="col-12 col-lg-10 px-3 text-center">
        <hr class="pb-5" />
        <h3 class="font-weight-bold">Du hast Fragen?</h3>
        <span class>Wir beraten dich gerne!</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Form",
  mounted() {
    if ("FA_Handler" in window) window.FA_Handler.refresh();
  },
};
</script>
